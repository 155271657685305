import React, { useEffect, useRef } from 'react';
import { useSpring, animated } from '@react-spring/web';
import styled, { keyframes } from 'styled-components';

const stepsData = [
  {
    image: 'https://cdn.builder.io/api/v1/image/assets/TEMP/5d73421bb73ef9330b5f83a442bbe9c247e52b9c593ca4a150884ed0de3690ff?apiKey=35bf81d825094f66b687d157fe963e7b&',
    year: '2018-2020',
    description: 'Аккаунт менеджер Амазон \nСредняя зп 1700$',
  },
  {
    image: 'https://cdn.builder.io/api/v1/image/assets/TEMP/91c5ef4ea66650972c029732553af8f79d22f210293265eb39a18e01690f1a0f?apiKey=35bf81d825094f66b687d157fe963e7b&',
    year: '2020-2022',
    description: 'Бизнес по системе онлайн арбитраж \nСредняя выручка 60.000$',
  },
  {
    image: 'https://cdn.builder.io/api/v1/image/assets/TEMP/4facdf3289abcc46ee877475d941bd782b0e94685e1e836da493c565418ae190?apiKey=35bf81d825094f66b687d157fe963e7b&',
    year: '2022 – по н. вр.',
    description: 'Запускаю товары по системе Private Label, \nобщая выручка > 1 миллиона $',
  },
];

const JourneySection: React.FC = () => {
  const stepsRef = useRef<HTMLDivElement[]>([]);
  const lineRef = useRef<SVGLineElement>(null);

  const [{ strokeDashoffset }, setStrokeDashoffset] = useSpring(() => ({
    strokeDashoffset: 100,
    config: { duration: 2000 },
  }));

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry, index) => {
          if (entry.isIntersecting) {
            setTimeout(() => {
              entry.target.classList.add('visible');
              if (lineRef.current) {
                const dashOffset = 100 - (index + 1) * (100 / stepsData.length);
                setStrokeDashoffset({ strokeDashoffset: dashOffset });
              }
            }, index * 800);
          }
        });
      },
      {
        threshold: 0.5,
      }
    );

    stepsRef.current.forEach((step) => observer.observe(step));

    return () => observer.disconnect();
  }, [setStrokeDashoffset]);

  return (
    <DivMain>
      <Container>
        <InnerContainer>
          <Title>МОЙ ПУТЬ</Title>
          <StepsContainer>
            <SvgContainer>
              <svg height="100%" width="100%">
                <animated.line
                  ref={lineRef}
                  x1="50"
                  y1="50%"
                  x2="calc(100% - 50)"
                  y2="50%"
                  strokeDashoffset={strokeDashoffset}
                />
              </svg>
            </SvgContainer>
            {stepsData.map((step, index) => (
              <Step
                key={index}
                ref={(el: HTMLDivElement | null) => {
                  if (el) stepsRef.current[index] = el;
                }}
              >
                <StepContent>
                  <StepImage loading="lazy" src={step.image} />
                  <StepYear>{step.year}</StepYear>
                  <StepDescription>{step.description}</StepDescription>
                </StepContent>
              </Step>
            ))}
          </StepsContainer>
        </InnerContainer>
        <WhyThisCourseContainer>
          Почему <span style={{ color: 'rgba(255,153,0,1)' }}>вам стоит</span> пройти этот курс
        </WhyThisCourseContainer>

        <AdvantagesContainer>
          <AdvantagesContent>
            <AdvantageColumn>
              <Advantage>
                <AdvantageImage
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/75e700109e9a9d8a49202801d5c606a194a2f8b0d6255aa7c58f01dad1dea6c2?apiKey=35bf81d825094f66b687d157fe963e7b&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/75e700109e9a9d8a49202801d5c606a194a2f8b0d6255aa7c58f01dad1dea6c2?apiKey=35bf81d825094f66b687d157fe963e7b&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/75e700109e9a9d8a49202801d5c606a194a2f8b0d6255aa7c58f01dad1dea6c2?apiKey=35bf81d825094f66b687d157fe963e7b&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/75e700109e9a9d8a49202801d5c606a194a2f8b0d6255aa7c58f01dad1dea6c2?apiKey=35bf81d825094f66b687d157fe963e7b&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/75e700109e9a9d8a49202801d5c606a194a2f8b0d6255aa7c58f01dad1dea6c2?apiKey=35bf81d825094f66b687d157fe963e7b&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/75e700109e9a9d8a49202801d5c606a194a2f8b0d6255aa7c58f01dad1dea6c2?apiKey=35bf81d825094f66b687d157fe963e7b&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/75e700109e9a9d8a49202801d5c606a194a2f8b0d6255aa7c58f01dad1dea6c2?apiKey=35bf81d825094f66b687d157fe963e7b&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/75e700109e9a9d8a49202801d5c606a194a2f8b0d6255aa7c58f01dad1dea6c2?apiKey=35bf81d825094f66b687d157fe963e7b&"
                />
                <AdvantageTitle>Огромные перспективы</AdvantageTitle>
                <AdvantageDescription>
                  Амазон – третья поисковая система в мире после Google и Youtube. Прибыль компании ежегодно растет.
                </AdvantageDescription>
              </Advantage>
            </AdvantageColumn>
            <AdvantageColumn>
              <Advantage>
                <AdvantageImage
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/5a743c1ebf1890c05aba9f32fc83544f2a05dc749dd5ac1c75b33a8b43726963?apiKey=35bf81d825094f66b687d157fe963e7b&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/5a743c1ebf1890c05aba9f32fc83544f2a05dc749dd5ac1c75b33a8b43726963?apiKey=35bf81d825094f66b687d157fe963e7b&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/5a743c1ebf1890c05aba9f32fc83544f2a05dc749dd5ac1c75b33a8b43726963?apiKey=35bf81d825094f66b687d157fe963e7b&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/5a743c1ebf1890c05aba9f32fc83544f2a05dc749dd5ac1c75b33a8b43726963?apiKey=35bf81d825094f66b687d157fe963e7b&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/5a743c1ebf1890c05aba9f32fc83544f2a05dc749dd5ac1c75b33a8b43726963?apiKey=35bf81d825094f66b687d157fe963e7b&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/5a743c1ebf1890c05aba9f32fc83544f2a05dc749dd5ac1c75b33a8b43726963?apiKey=35bf81d825094f66b687d157fe963e7b&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/5a743c1ebf1890c05aba9f32fc83544f2a05dc749dd5ac1c75b33a8b43726963?apiKey=35bf81d825094f66b687d157fe963e7b&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/5a743c1ebf1890c05aba9f32fc83544f2a05dc749dd5ac1c75b33a8b43726963?apiKey=35bf81d825094f66b687d157fe963e7b&"
                />
                <AdvantageTitle>Жизнь мечты</AdvantageTitle>
                <AdvantageDescription>
                  Бизнес на Амазоне позволит вам не зависеть от начальника и жить в любой точке мира. Вы будете зарабатывать деньги в долларах и не переживать за свое будущее
                </AdvantageDescription>
              </Advantage>
            </AdvantageColumn>
            <AdvantageColumn>
              <Advantage>
                <AdvantageImage
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/b84f7c03b34383c802584b892fbbd894aac696ad3fcbbfb8a75a4fa43fd2254e?apiKey=35bf81d825094f66b687d157fe963e7b&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/b84f7c03b34383c802584b892fbbd894aac696ad3fcbbfb8a75a4fa43fd2254e?apiKey=35bf81d825094f66b687d157fe963e7b&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b84f7c03b34383c802584b892fbbd894aac696ad3fcbbfb8a75a4fa43fd2254e?apiKey=35bf81d825094f66b687d157fe963e7b&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/b84f7c03b34383c802584b892fbbd894aac696ad3fcbbfb8a75a4fa43fd2254e?apiKey=35bf81d825094f66b687d157fe963e7b&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/b84f7c03b34383c802584b892fbbd894aac696ad3fcbbfb8a75a4fa43fd2254e?apiKey=35bf81d825094f66b687d157fe963e7b&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b84f7c03b34383c802584b892fbbd894aac696ad3fcbbfb8a75a4fa43fd2254e?apiKey=35bf81d825094f66b687d157fe963e7b&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/b84f7c03b34383c802584b892fbbd894aac696ad3fcbbfb8a75a4fa43fd2254e?apiKey=35bf81d825094f66b687d157fe963e7b&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/b84f7c03b34383c802584b892fbbd894aac696ad3fcbbfb8a75a4fa43fd2254e?apiKey=35bf81d825094f66b687d157fe963e7b&"
                />
                <AdvantageTitle>Реальные кейсы</AdvantageTitle>
                <AdvantageDescription>
                  Вы можете довериться мне, ведь я действующий селлер и на своем примере доказываю, что на этом можно много зарабатывать. Примеры моих учеников также это подтверждают.
                </AdvantageDescription>
              </Advantage>
            </AdvantageColumn>
          </AdvantagesContent>
        </AdvantagesContainer>
      </Container>
    </DivMain>
  );
};

const DivMain = styled.div`
  width: 100%;
  margin: 0 auto;
  overflow-x: hidden; /* Отключаем горизонтальный скролл */
`;

const fadeInVertical = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeInHorizontal = keyframes`
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const drawLine = keyframes`
  from {
    stroke-dashoffset: 100%;
  }
  to {
    stroke-dashoffset: 0%;
  }
`;

const Container = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #2c2d2f;
  padding: 45px 60px;
  box-sizing: border-box;
  @media (max-width: 991px) {
    padding: 20px;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Title = styled.div`
  font-feature-settings: 'clig' off, 'liga' off;
  font: 700 64px/110% Bebas Neue, sans-serif;
  text-align: center;
  width: 100%;
  @media (max-width: 991px) {
    font-size: 40px;
  }
`;

const StepsContainer = styled.div`
  display: flex;
  justify-content: center; /* Центрирование содержимого */
  margin-top: 71px;
  width: 100%;
  align-items: center;
  position: relative;
  gap: 20px;
  overflow-x: hidden; /* Убираем внутренний горизонтальный скролл */
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    overflow-x: visible; /* Устанавливаем видимость для мобильных устройств */
  }
`;

const SvgContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 2px;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  overflow: hidden; /* Ограничиваем видимость внутри контейнера */
  svg {
    width: 100%;
    height: 100%;
  }
  line {
    stroke: #e5e5e5;
    stroke-width: 2;
    stroke-dasharray: 100%;
    stroke-dashoffset: 100%;
    animation: ${drawLine} 3s ease forwards;
  }
  @media (max-width: 991px) {
    width: 2px;
    height: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden; /* Ограничиваем видимость внутри контейнера */
    line {
      x1: 0;
      y1: 0;
      x2: 0;
      y2: 100%;
    }
  }
`;

const Step = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  z-index: 2;
  opacity: 0;
  transition: opacity 0.5s ease, transform 0.5s ease;
  max-width: 100%; /* Устанавливаем максимальную ширину */
  &.visible {
    opacity: 1;
    animation: ${fadeInHorizontal} 0.8s ease forwards;
    @media (max-width: 991px) {
      animation: ${fadeInVertical} 0.8s ease forwards;
    }
  }
`;

const StepContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  width: 100%; /* Ограничение ширины */
  box-sizing: border-box; /* Учет padding в ширине */
  overflow: hidden; /* Обрезка выходящих за границы элементов */
  @media (max-width: 991px) {
    align-items: center;
  }
`;

const StepImage = styled.img`
  width: 100px;
  max-width: 100%;
  z-index: 2;
`;

const StepYear = styled.div`
  font-feature-settings: 'clig' off, 'liga' off;
  margin-top: 24px;
  font: 700 32px/120% Jost, sans-serif;
  z-index: 2;
`;

const StepDescription = styled.div`
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  align-self: stretch;
  margin-top: 16px;
  font: 400 18px/23px Jost, sans-serif;
  z-index: 2;
  word-wrap: break-word; /* Обеспечение переноса текста */
  overflow-wrap: break-word; /* Обеспечение переноса текста */
`;

const WhyThisCourseContainer = styled.div`
  color: #f90;
  font: 700 64px/100% Bebas Neue, sans-serif;
  margin-top: 64px;
  text-align: center;
  @media (max-width: 991px) {
    font-size: 40px;
  }
`;

const AdvantagesContainer = styled.div`
  margin-top: 64px;
  width: 100%;
  padding: 0 20px;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const AdvantagesContent = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const AdvantageColumn = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 33%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Advantage = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  @media (max-width: 991px) {
    margin-top: 24px;
  }
`;

const AdvantageImage = styled.img`
  aspect-ratio: 1.3;
  object-fit: auto;
  object-position: center;
  width: 100%;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const AdvantageTitle = styled.div`
  color: #0e0b2c;
  font-feature-settings: 'clig' off, 'liga' off;
  margin-top: 20px;
  font: 700 24px/120% Jost, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const AdvantageDescription = styled.div`
  color: #909090;
  font-feature-settings: 'clig' off, 'liga' off;
  margin-top: 10px;
  font: 400 18px/23px Jost, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export default JourneySection;
