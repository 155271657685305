import React from 'react';
import HomePage from './pages/HomePage';
import './styles.css';
import GlobalStyle from './GlobalStyle';


const App: React.FC = () => {
    return (
        <div>
            <GlobalStyle />
            <HomePage />
        </div>
    );
};

export default App;
