// GlobalStyle.js
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Bebas Neue';
    src: url('/fonts/BebasNeue-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Bebas Neue';
    src: url('/fonts/BebasNeue-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'Bebas Neue';
    src: url('/fonts/BebasNeue-Book.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Bebas Neue';
    src: url('/fonts/BebasNeue-Light.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
  }

  @font-face {
    font-family: 'Bebas Neue';
    src: url('/fonts/BebasNeue-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
  }

  body {
    font-family: 'Bebas Neue', sans-serif;
    margin: 0;
    padding: 0;
  }
`;

export default GlobalStyle;
