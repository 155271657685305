import * as React from "react";
import styled from "styled-components";

const ForWho: React.FC = () => {
  return (
    <DivMain id="forwho">
      <Div>
        <Header>
          <Title>Кому подойдет</Title>
          <Description>
            Освоите торговлю на Amazon по стратегии онлайн арбитраж и создадите стабильный полупассивный заработок
          </Description>
        </Header>
        <Divider />
        <Content>
          <Row>
            <Card>
              <CardContent>
                <CardTitleContainer>
                  <CardTitle>Новичкам в e-commerce</CardTitle>

                </CardTitleContainer>
                <CardDivider />
                <CardDescription>
                  Курс начинается с основ и подойдет для тех, кто не знаком с e-commerce. После прохождения курса вы сможете сразу же применять полученные знания на практике и зарабатывать.
                </CardDescription>
                <CardFooterImage
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/b5836136f59c794b915d2d75388c527f0e2f3b7077bcdefdbc95ea957c0bb283?apiKey=35bf81d825094f66b687d157fe963e7b&"
                />
              </CardContent>
            </Card>

            <Card>
              <CardContent>
                <CardTitleContainer>
                  <CardTitle>Действующим селлерам</CardTitle>

                </CardTitleContainer>
                <CardDivider />
                <CardDescription>
                  Уперлись в потолок в доходе? Устали от штрафов и нововведений локальных маркетплейсов? Хотите зарабатывать в стабильной валюте? Тогда этот курс отлично подойдет вам.
                </CardDescription>
                <CardFooterImage
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/90129ac298b3f943961fafa960f5975f6f71af0259bbabc6c28a8eaf444fdc01?apiKey=35bf81d825094f66b687d157fe963e7b&"
                />
              </CardContent>
            </Card>
            <Card>
              <CardContent>
                <CardTitleContainer>
                  <CardTitle>Инвесторам</CardTitle>

                </CardTitleContainer>
                <CardDivider />
                <CardDescription>
                  Главное правило каждого инвестора – диверсификация. Добавьте в свое портфолио еще один актив – товарный бизнес на Амазон с потенциальной маржинальностью 20%.
                </CardDescription>
                <CardFooterImage
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/716d9715c679716e8374baa1522a553741f8e5292d53d788fec5a6528a64cde2?apiKey=35bf81d825094f66b687d157fe963e7b&"
                />
              </CardContent>
            </Card>
          </Row>
        </Content>
        <Footer>
          Мы предлагаем гибкие варианты обучения адаптированные к вашему расписанию и стилю обучения, чтобы вы могли учиться на ходу и из любого места
        </Footer>
        <FooterInfo>
          <FooterImage
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/eb203e1c558023855a931dd906c13498cd98862a9ceaf71fa5a429a933d8647c?apiKey=35bf81d825094f66b687d157fe963e7b&"
          />
          <FooterTextContainer>
            <FooterTextTitle>100 + учеников</FooterTextTitle>
            <FooterText>
              Освоите торговлю на Amazon по стратегии онлайн арбитраж и создадите стабильный полупассивный заработок
            </FooterText>
          </FooterTextContainer>
        </FooterInfo>
      </Div>
    </DivMain>
  );
}

const DivMain = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
`;

const Div = styled.div`
  border-radius: 24px;
  background: linear-gradient(160deg, #0b172e -0.66%, #2b4a87 176.61%);
  display: flex;
  margin-top: 125px;
  width: 100%;
  max-width: 1200px;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  box-sizing: border-box;
  @media (max-width: 991px) {
    max-width: 100%;
        margin: 20px; /* Add margin-top for mobile screens */
        margin-top: 50px;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  padding-bottom: 10px;
  gap: 20px;
  color: #fff;
  text-align: center;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Title = styled.div`
  font: 700 64px/100% Bebas Neue, sans-serif;
  @media (max-width: 991px) {
    font-size: 40px;
  }
`;

const Description = styled.div`
  leading-trim: both;
  text-edge: cap;
  align-self: start;
  margin-top: 12px;
  font: 400 18px/24px Jost, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Divider = styled.div`
  background-color: rgba(144, 144, 144, 0.6);
  margin-top: 40px;
  width: 100%;
  height: 1px;
`;

const Content = styled.div`
  margin-top: 40px;
  width: 100%;
  max-width: 1200px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
`;

const Card = styled.div`
  display: flex;
  position: relative;
  line-height: normal;
  width: calc(33.33% - 20px);
  background: #fff;
  border-radius: 24px;
  overflow: hidden;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  @media (max-width: 1650px) {
    padding: 15px;
  }
  @media (max-width: 1200px) {
    padding: 10px;
  }
`;

const CardTitleContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
    justify-content: center; /* Центровка содержимого */

  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const CardTitle = styled.div`
  color: #0e0b2c;
  font: 700 29px/110% Jost, sans-serif;
  
  @media (max-width: 1650px) {
    font-size: 25px;
  }
  @media (max-width: 1200px) {
    font-size: 22px;
  }
  @media (max-width: 991px) {
    font-size: 20px;
  }  
`;

const CardIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 58px;
  height: 58px;
  background-color: #ff9900;
  border-radius: 50%;
  @media (max-width: 1650px) {
    width: 55px;
    height: 55px;
  }
  @media (max-width: 1200px) {
    width: 48px;
    height: 48px;
  }
  @media (max-width: 991px) {
    width: 40px;
    height: 40px;
  }  
`;

const CardIcon = styled.img`
  width: 28px;
  height: 28px;
  @media (max-width: 1650px) {
    width: 24px;
    height: 24px;
  }
  @media (max-width: 1200px) {
    width: 20px;
    height: 20px;
  }
  @media (max-width: 991px) {
    width: 16px;
    height: 16px;
  }  
`;

const CardDivider = styled.div`
  background-color: rgba(14, 11, 44, 0.1);
  margin-top: 30px;
  height: 1px;
  width: 100%;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const CardDescription = styled.div`
  color: #0e0b2c;
  margin-top: 24px;
  font: 400 16px/22px Jost, sans-serif;
  text-align: center; /* Центровка текста */
  @media (max-width: 1650px) {
    font-size: 14px;
  }
  @media (max-width: 1200px) {
    font-size: 12px;
  }
  @media (max-width: 991px) {
    font-size: 14px;
  }
`;


const CardFooterImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 0 0 24px 24px;
  margin-top: 20px;
`;

const Footer = styled.div`
  color: #fff;
  background: linear-gradient(116deg, #ff9900 5.3%, #ffd494 94.16%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  align-self: stretch;
  margin: 83px 15px 0 11px;
  font: 700 64px/77px Bebas Neue, sans-serif;
  text-align: center;
  @media (max-width: 991px) {
    font-size: 40px;
    line-height: 53px;
    margin: 40px 10px 0 0;
  }
`;

const FooterInfo = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;
  margin: 22px 15px 0 11px;
  align-self: stretch;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const FooterImage = styled.img`
  aspect-ratio: 1.37;
  object-fit: cover;
  width: 100%;
  max-width: 300px;
`;

const FooterTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 0;
`;

const FooterTextTitle = styled.div`
  background-color: #fff;
  color: #ff9900;
  padding: 24px;
  font: 600 64px/120% Jost, sans-serif;
  border-radius: 24px;
  @media (max-width: 991px) {
    font-size: 40px;
    padding: 20px;
  }
`;

const FooterText = styled.div`
  color: rgba(144, 144, 144, 0.6);
  text-align: right;
  margin-top: 32px;
  font: 400 18px/24px Jost, sans-serif;
  @media (max-width: 991px) {
    font-size: 14px;
  }
`;

export default ForWho;
