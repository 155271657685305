import React from 'react';
import Header from '../components/Header';
import HeroSection from '../components/HeroSection';
import JourneySection from '../components/JourneySection';
import CourseSection from '../components/CourseSection';
import FAQSection from '../components/FAQSection';
import Footer from '../components/Footer';
import { GradientContainer } from '../styles';
import CourseAuthor from '../components/CourseAuthor';
import ForWho from '../components/ForWho';

const HomePage: React.FC = () => {
    return (
        <div>
            <Header />
            <HeroSection />
            <CourseAuthor />
            <JourneySection />
            <CourseSection />
            <ForWho />
            <FAQSection />
            <Footer />
        </div>
    );
};

export default HomePage;
