import React from 'react';
import styled from 'styled-components';
import Img2 from '../assets/photo/image2.jpg';
import Img5 from '../assets/photo/image5.jpg';
import Img6 from '../assets/photo/image6.jpg';

const HeroSection: React.FC = () => {
    return (
        <HeroContainer>
            <HeroContent>
                <HeaderTextContainer>
                    <MainHeaderText>
                        Обретите свободу и начните зарабатывать в <YellowText>$$$</YellowText> из любой точки мира
                    </MainHeaderText>
                </HeaderTextContainer>
                <SubHeaderTextContainer>
                    <SubHeaderText>
                        Присоединяйтесь к растущему сообществу предпринимателей, которые повысили свои продажи на Amazon и расширили свою деятельность с помощью наших экспертных руководств.
                    </SubHeaderText>
                    <LearnButtonContainer href="#courses">
                        <LearnButton>Начать учиться</LearnButton>
                    </LearnButtonContainer>
                </SubHeaderTextContainer>
            </HeroContent>

            <HeroImagesContainer>
                <SemiCircleRight />

                <Image src={Img6} style={{ transform: 'rotate(-17.16deg)' }} />
                <Image src={Img5} style={{ transform: 'rotate(19.25deg)' }} />
                <GraduatesContainer>
                    <Ellipse />
                    <GraduatesNumber>100+</GraduatesNumber>
                    <GraduatesText>выпускников</GraduatesText>
                </GraduatesContainer>
                <Image src={Img2} style={{ transform: 'rotate(-17.16deg)' }} />
                <SemiCircleLeft />

            </HeroImagesContainer>
        </HeroContainer>
    );
};

const HeroContainer = styled.div`
    width: 100%;
    background: linear-gradient(to bottom, #001B4F 0%, #004EE4 120%);
    padding-bottom: 250px;
    overflow: hidden;
    z-index: 1;
    @media (max-width: 991px) {
        padding-bottom: 40px;
    }
`;

const HeroContent = styled.div`
    display: flex;
    gap: 20px;
    margin-top: 90px;
    padding: 0 20px;
    box-sizing: border-box;
    @media (max-width: 991px) {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
    }
`;

const HeaderTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 50%;
    margin-left: 40px;
    @media (max-width: 991px) {
        width: 100%;
        margin-left: 0;
    }
`;

const MainHeaderText = styled.div`
    font-family: 'Bebas Neue', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 100%;
    margin-left: 90px;
    color: #FFFFFF;
    @media (max-width: 991px) {
        max-width: 100%;
        margin-top: 40px;
        font-size: 40px;
        line-height: 45px;
        margin-left: 0;
        margin-bottom: 10px; /* Added margin for spacing */
    }
`;

const YellowText = styled.span`
    color: rgba(255, 153, 0, 1);
`;

const SubHeaderTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 50%;
    margin-left: 20px;
    @media (max-width: 991px) {
        width: 100%;
        margin-left: 0;
    }
`;

const SubHeaderText = styled.div`
    color: #fff;
    font: 300 18px/24px 'Jost', sans-serif;
    @media (max-width: 991px) {
        max-width: 100%;
    }
`;

const LearnButtonContainer = styled.a`
    align-self: start;
    display: flex;
    margin-top: 56px;
    margin-bottom: 50px;
    gap: 8px;
    text-decoration: none;
    @media (max-width: 991px) {
        width: 100%;
        margin-top: 40px;
    }
`;

const LearnButton = styled.div`
    justify-content: center;
    border-radius: 140px;
    border-color: rgba(255, 153, 0, 1);
    border-style: solid;
    border-width: 1px;
    color: #fff;
    padding: 14px 30px;
    font: 400 14px 'Jost', sans-serif;
    @media (max-width: 991px) {
        width: 100%;
        padding: 10px 20px;
        text-align: center;
    }
`;

const HeroImagesContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    position: relative;
    margin-top: 40px;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 10px 20px;
    box-sizing: border-box;

`;

const Image = styled.img`
    width: 349.9px;
    height: 248.07px;
    border-radius: 20px;
    object-fit: cover;
    @media (max-width: 768px) {
        width: 200px;
        height: 150px;
    }
`;

const SemiCircleLeft = styled.div`
    width: 129px;
    height: 259px;
    background: url('data:image/svg+xml;utf8,<svg width="129" height="259" viewBox="0 0 129 259" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M129 258.453C112.059 258.453 95.2848 255.116 79.6338 248.634C63.9828 242.151 49.762 232.649 37.7832 220.67C25.8045 208.691 16.3024 194.47 9.81954 178.819C3.33669 163.168 8.87953e-06 146.394 9.62002e-06 129.453C1.03605e-05 112.513 3.33669 95.738 9.81955 80.087C16.3024 64.436 25.8045 50.2151 37.7832 38.2363C49.762 26.2576 63.9828 16.7555 79.6338 10.2727C95.2848 3.7898 112.059 0.453123 129 0.453125L129 129.453L129 258.453Z" fill="%23FF9900"/></svg>');
    z-index: 1;
    transform: rotate(180deg);
    @media (max-width: 768px) {
        width: 80px;
        height: 160px;
    }
`;

const SemiCircleRight = styled.div`
   width: 129px;
    height: 259px;
    background: url('data:image/svg+xml;utf8,<svg width="129" height="259" viewBox="0 0 129 259" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M129 258.453C112.059 258.453 95.2848 255.116 79.6338 248.634C63.9828 242.151 49.762 232.649 37.7832 220.67C25.8045 208.691 16.3024 194.47 9.81954 178.819C3.33669 163.168 8.87953e-06 146.394 9.62002e-06 129.453C1.03605e-05 112.513 3.33669 95.738 9.81955 80.087C16.3024 64.436 25.8045 50.2151 37.7832 38.2363C49.762 26.2576 63.9828 16.7555 79.6338 10.2727C95.2848 3.7898 112.059 0.453123 129 0.453125L129 129.453L129 258.453Z" fill="%23FF9900"/></svg>');
    z-index: 1;
    @media (max-width: 768px) {
        width: 80px;
        height: 160px;
    }
`;

const GraduatesContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transform: rotate(8.61deg);
    width: 268px;
    height: 268px;
    @media (max-width: 768px) {
        width: 150px;
        height: 150px;
    }
`;

const GraduatesNumber = styled.div`
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 700;
    font-size: 88px;
    line-height: 100%;
    color: #FFFFFF;
    @media (max-width: 768px) {
        font-size: 50px;
    }
`;

const GraduatesText = styled.div`
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 29px;
    color: #FFFFFF;
    transform: rotate(0.28deg); /* Minor adjustment to make it horizontal */
    @media (max-width: 768px) {
        font-size: 12px;
    }
`;

const Ellipse = styled.div`
    position: absolute;
    width: 268px;
    height: 268px;
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(5.45px);
    border-radius: 50%;
    z-index: -1; /* Make sure the ellipse is behind the text */
    @media (max-width: 768px) {
        width: 150px;
        height: 150px;
    }
`;

export default HeroSection;
