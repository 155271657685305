import React from 'react';
import styled from 'styled-components';
import Header from '../components/Header';
import Footer from '../components/Footer';

const TermsPage: React.FC = () => {
    return (
        <div>
            <Header />
            <TermsContainer>
                <TermsTitle>Пользовательское соглашение</TermsTitle>
                <TermsSection>
                    <SectionTitle>1. Общие положения</SectionTitle>
                    <SectionContent>
                        <p>1.1. Пользуясь услугами Компании, вы соглашаетесь с условиями настоящего Соглашения.</p>
                        <p>1.2. Компания оставляет за собой право изменять условия Соглашения без предварительного уведомления. Новая редакция Соглашения вступает в силу с момента её размещения на Сайте.</p>
                    </SectionContent>
                </TermsSection>
                <TermsSection>
                    <SectionTitle>2. Описание услуг</SectionTitle>
                    <SectionContent>
                        <p>2.1. Компания предоставляет доступ к обучающим курсам по теме торговли на платформе Amazon (далее — Курсы).</p>
                        <p>2.2. Доступ к Курсам предоставляется после успешной оплаты Пользователем.</p>
                    </SectionContent>
                </TermsSection>
                <TermsSection>
                    <SectionTitle>3. Права и обязанности сторон</SectionTitle>
                    <SectionContent>
                        <p>3.1. Пользователь имеет право:</p>
                        <ul>
                            <li>Получать доступ к Курсам в соответствии с условиями Соглашения.</li>
                            <li>Получать консультации и поддержку от Компании в рамках Курсов.</li>
                        </ul>
                        <p>3.2. Пользователь обязуется:</p>
                        <ul>
                            <li>Не распространять полученные материалы Курсов без согласия Компании.</li>
                            <li>Не использовать Курсы в целях, противоречащих законодательству Российской Федерации.</li>
                            <li>Указывать достоверные данные при регистрации и оплате.</li>
                        </ul>
                        <p>3.3. Компания имеет право:</p>
                        <ul>
                            <li>Ограничить доступ к Курсам в случае нарушения Пользователем условий Соглашения.</li>
                            <li>Обновлять содержание Курсов и условия их предоставления.</li>
                        </ul>
                        <p>3.4. Компания обязуется:</p>
                        <ul>
                            <li>Обеспечить доступ Пользователя к Курсам после получения оплаты.</li>
                            <li>Предоставлять поддержку Пользователю в рамках Курсов.</li>
                        </ul>
                    </SectionContent>
                </TermsSection>
                <TermsSection>
                    <SectionTitle>4. Оплата и возврат средств</SectionTitle>
                    <SectionContent>
                        <p>4.1. Оплата за Курсы производится через платежные системы, указанные на Сайте.</p>
                        <p>4.2. В случае невозможности предоставления Курсов по вине Компании, Пользователь имеет право на возврат средств.</p>
                        <p>4.3. Возврат средств не производится, если Пользователь получил доступ к Курсам и начал обучение.</p>
                    </SectionContent>
                </TermsSection>
                <TermsSection>
                    <SectionTitle>5. Конфиденциальность</SectionTitle>
                    <SectionContent>
                        <p>5.1. Компания обязуется не передавать личные данные Пользователя третьим лицам без его согласия, за исключением случаев, предусмотренных законодательством.</p>
                        <p>5.2. Компания принимает меры для защиты личных данных Пользователя в соответствии с Политикой конфиденциальности.</p>
                    </SectionContent>
                </TermsSection>
                <TermsSection>
                    <SectionTitle>6. Ответственность</SectionTitle>
                    <SectionContent>
                        <p>6.1. Компания не несет ответственности за невозможность использования Курсов по причинам, не зависящим от Компании.</p>
                        <p>6.2. Компания не несет ответственности за убытки, понесенные Пользователем в результате использования Курсов, если такие убытки не вызваны умышленными действиями Компании.</p>
                    </SectionContent>
                </TermsSection>
                <TermsSection>
                    <SectionTitle>7. Заключительные положения</SectionTitle>
                    <SectionContent>
                        <p>7.1. Все споры, возникающие из настоящего Соглашения, подлежат разрешению в соответствии с законодательством Российской Федерации.</p>
                        <p>7.2. В случае признания любого из положений Соглашения недействительным, остальные положения остаются в силе.</p>
                    </SectionContent>
                </TermsSection>
                <TermsSection>
                    <SectionTitle>Дата последнего обновления</SectionTitle>
                    <SectionContent>
                        <p>26 Июня 2024 года</p>
                    </SectionContent>
                </TermsSection>
            </TermsContainer>
            <Footer />
        </div>
    );
};

const TermsContainer = styled.div`
  margin: 0 auto;
  padding: 40px;
  background-color: #001B4F;
  color: #fff;
  font-family: 'Inter', sans-serif;
`;

const TermsTitle = styled.h1`
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const TermsSection = styled.div`
  margin-bottom: 40px;
`;

const SectionTitle = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const SectionContent = styled.div`
  font-size: 16px;
  line-height: 1.6;
  color: #ddd;
`;

export default TermsPage;
