// src/pages/PaymentSuccessPage.tsx
import React from 'react';
import styled from 'styled-components';
import Header from '../components/Header';
import Footer from '../components/Footer';

const PaymentSuccessPage: React.FC = () => {
    return (
        <div>
            <Header />
            <SuccessContainer>
                <SuccessTitle>Payment Successful!</SuccessTitle>
                <SuccessMessage>Thank you for your purchase. Your payment was processed successfully.</SuccessMessage>
                <SuccessMessage>You will receive a confirmation email shortly with the details of your purchase.</SuccessMessage>
            </SuccessContainer>
            <Footer />
        </div>
    );
};

const SuccessContainer = styled.div`
    margin: 0 auto;
    padding: 40px;
    background-color: #001B4F;
    color: #fff;
    font-family: 'Inter', sans-serif;
    text-align: center;
`;

const SuccessTitle = styled.h1`
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
`;

const SuccessMessage = styled.p`
    font-size: 18px;
    margin-bottom: 20px;
`;

export default PaymentSuccessPage;
