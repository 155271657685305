import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, useRoutes } from 'react-router-dom';
import App from './App';
import TermsPage from './pages/TermsPage';
import GlobalStyle from './GlobalStyle';
import PaymentSuccessPage from './pages/PaymentSuccessPage';

const AppRoutes = () => {
    const routes = useRoutes([
        { path: "/", element: <App /> },
        { path: "/terms", element: <TermsPage /> },
        { path: "/payment-success", element: <PaymentSuccessPage /> }, // Add this line

    ]);
    return routes;
};

ReactDOM.render(
    <Router>
        <GlobalStyle />
        <AppRoutes />
    </Router>,
    document.getElementById('root')
);
